import './src/styles/global.less';
import './src/fonts/fonts.less';

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { AuthProvider } from '_contexts/auth';

const stripe = loadStripe(process.env.GATSBY_STRIPE_PKEY);

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <Elements stripe={stripe}>{element}</Elements>
  </AuthProvider>
);

export const onRouteUpdate = () => {
  window?.analytics && window.analytics.page();
};
