// @flow
import { gql } from 'graphql-request';

import { runQuery } from '_client';
import { GqlError } from '_utils/errors';

const USER_SIGN_IN_BY_CODE = gql`
  mutation userSignInByCode($code: String!) {
    userSignInByCode(code: $code) {
      accessToken
    }
  }
`;

export const getTokenBySignInWithMagicLink = async (code: string): Promise<string> => {
  try {
    const { userSignInByCode } = await runQuery(USER_SIGN_IN_BY_CODE, { code });
    return userSignInByCode.accessToken;
  } catch (err) {
    throw new GqlError(err);
  }
};

export default getTokenBySignInWithMagicLink;
