// @flow
import { gql } from 'graphql-request';

import { runQuery } from '_client';
import { GqlError } from '_utils/errors';

import type { ProfileUpdateRequest } from './types';

const UPDATE_PROFILE = gql`
  mutation updateProfile($user: UserArgs!) {
    updateUser(user: $user) {
      id
      firstName
      lastName
      gender
      knownAs
      avatarURL
      birthday
    }
  }
`;

export const updateProfile = async (profile: ProfileUpdateRequest): Promise<Object> => {
  try {
    const { updateUser } = await runQuery(UPDATE_PROFILE, { user: profile });
    return updateUser;
  } catch (err) {
    throw new GqlError(err);
  }
};

const ME = gql`
  query me {
    me {
      id
      email
      firstName
      lastName
      gender
      knownAs
      status
      avatarURL
      profilePictureURL
      birthday
      idType
      fitnessProfile
      createdAt
      userPreference {
        preferences
      }
      membership {
        status
        startDate
        endDate
        packageName
        packageType
        trialist
        freeAppAccess
        isAggregator
      }
      entitlement {
        subscriptionId
        purchaseDate
        expiresDate
        periodType
        planType
        store
        status
      }
      stripeCustomer {
        id
        paymentMethods {
          id
          type
          card {
            expMonth
            expYear
            last4
            brand
          }
        }
        invoiceSettings {
          defaultPaymentMethod {
            id
          }
        }
        nextInvoice {
          id
          amountDue
          total
        }
      }
    }
  }
`;

export const getMe = async (): Promise<Object> => {
  try {
    const { me } = await runQuery(ME);
    return me;
  } catch (err) {
    if (!err.response.data?.me) {
      throw new GqlError(err);
    }

    return err.response.data.me;
  }
};

const STRIPE_CUSTOMER = gql`
  query me {
    me {
      id
      stripeCustomer {
        id
        paymentMethods {
          id
          type
          card {
            expMonth
            expYear
            last4
            brand
          }
        }
        invoiceSettings {
          defaultPaymentMethod {
            id
          }
        }
        nextInvoice {
          id
          amountDue
          total
          amountPaid
          currency
        }
      }
    }
  }
`;

export const getStripeCustomer = async (): Promise<Object> => {
  try {
    const { me } = await runQuery(STRIPE_CUSTOMER);
    return me.stripeCustomer;
  } catch (err) {
    if (!err.response.data?.me?.stripeCustomer) {
      throw new GqlError(err);
    }

    return err.response.data.me.stripeCustomer;
  }
};
