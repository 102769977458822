// @flow
import { gql } from 'graphql-request';

import { runQuery } from '_client';
import { GqlError } from '_utils/errors';

const USER_SIGN_IN = gql`
  mutation userSignIn($email: String!, $password: String!) {
    userSignIn(email: $email, password: $password) {
      accessToken
    }
  }
`;

export const getTokenBySignInWithEmail = async (email: string, password: string): Promise<string> => {
  try {
    const { userSignIn } = await runQuery(USER_SIGN_IN, { email, password });
    return userSignIn.accessToken;
  } catch (err) {
    throw new GqlError(err);
  }
};
