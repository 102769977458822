// @flow
type ErrorInfo = {
  code: string,
  message: string,
};

export const getErrorMessage = (errInfo: ErrorInfo): string => {
  switch (errInfo.code) {
    case '801':
      return "The promo code isn't specified for this customer";

    default:
      return errInfo.message;
  }
};

export class GqlError extends Error {
  errors: $ReadOnlyArray<ErrorInfo>;

  constructor(error: Object) {
    console.log({error});
    const errors = error.response.errors.map(err => ({
      code: err.extensions?.code,
      message: err.message,
    }));
    console.log({errors});
    super(errors.length > 0 ? getErrorMessage(errors[0]) : errors);
  }
}
