// @flow
import React, {useContext} from 'react';

import type {Action, State} from './types';

export const DEFAULT_STATE = {
  currentUser: null,
  type: null,
  loading: true,
  restoring: true,
};

export const AuthStateContext: React$Context<?State> = React.createContext<?State>();

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SIGN_IN': {
      const {signInType, user} = action;

      if (user.fitnessProfile) {
        user.fitnessProfile = JSON.parse(user.fitnessProfile);
      }
      if (user.userPreference) {
        user.userPreference.preferences = JSON.parse(user.userPreference.preferences);
      }

      return {
        ...state,
        currentUser: user,
        type: signInType,
      };
    }

    case 'SIGN_OUT':
      return {
        ...DEFAULT_STATE,
        loading: false,
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      };

    case 'SET_RESTORING':
      return {
        ...state,
        restoring: action.restoring,
      };

    case 'RESTORE':
      return {
        ...state,
        currentUser: action.user,
      };

    case 'UPDATE_PROFILE': {
      const user = state.currentUser;
      return user
        ? {
            ...state,
            currentUser: {
              ...user,
              ...action.profile,
            },
          }
        : state;
    }

    case 'SET_METADATA':
      return {
        ...state,
        metadata: action.metadata,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const useAuthState = (): State => {
  const state = useContext(AuthStateContext);
  if (!state) {
    throw new Error('useAuthState must be used within an AuthProvider');
  }
  return state;
};
