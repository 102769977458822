// @flow

import {useCallback} from 'react';

type TrackOptions = {
  group?: string,
  name?: string,
  action?: string,
  data?: Object,
};

type IdentifyOptions = {
  id: string,
  data?: Object,
};

type AnalyticsData = {
  track: (options: TrackOptions) => void,
  identify: (options: IdentifyOptions) => void,
  reset: () => void,
};

const useAnalytics = (): AnalyticsData => {
  const track = useCallback(({group, name, action, data}: TrackOptions) => {
    if (!window?.analytics) return;

    const eventName = [group, name, action].filter(x => x).join(': ');
    if (eventName.length < 1) return;

    window.analytics.track(eventName, data);
  }, []);

  const identify = useCallback(({id, data}: IdentifyOptions) => {
    if (!window?.analytics) return;
    window.analytics.identify(id, data);
  }, []);

  const reset = useCallback(() => {
    if (!window?.analytics) return;
    window.analytics.reset();
  }, []);

  return {track, identify, reset};
};

export const event = {
  group: {
    Checkout: 'Checkout',
    Login: 'Login',
    Signup: 'Signup',
    Restore: 'Restore',
    Profile: 'Profile',
    Home: 'Home',
    ContactUs: 'Contact Us',
    Faq: 'FAQ',
    Privacy: 'Privacy Policy',
    Terms: 'Terms of Use',
    NotFound: 'Not Found',
    RTMContestRules: 'RTM Contest Rules',
    RTMBonusPrizeRules: 'RTM Bonus Prize Rules',
  },
  action: {
    Cancelled: 'Cancelled',
    Changed: 'Changed',
    Completed: 'Completed',
    Failed: 'Failed',
    Next: 'Next',
    Paused: 'Paused',
    Reset: 'Reset',
    Resumed: 'Resumed',
    Set: 'Set',
    Skipped: 'Skipped',
    Started: 'Started',
    Success: 'Success',
    Tapped: 'Tapped',
    Toggled: 'Toggled',
    Viewed: 'Viewed',
  },
  name: {
    OrderComplete: 'Order Complete',
  },
  af: {
    login: 'af_login',
    complete: 'af_complete_registration',
    addToCart: 'af_add_to_cart',
    addPaymentInfo: 'af_add_payment_info',
    subscribe: 'af_subscribe',
    startTrial: 'af_start_trial',
    redeemPromoCode: 'af_redeem_promocode',
  },
};

export default useAnalytics;
