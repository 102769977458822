// @flow
import React, {useEffect, useReducer} from 'react';

import useAnalytics, {event} from '_hooks/useAnalytics';
import {getMe} from '_utils/profile';

import {AuthDispatchContext, useAuth} from './dispatch';
import {AuthStateContext, DEFAULT_STATE, stateReducer, useAuthState} from './state';

type AuthProps = {
  children: React$Node,
};

const AuthProvider = ({children}: AuthProps): React$Node => {
  const [state, dispatch] = useReducer(stateReducer, DEFAULT_STATE);

  const {identify, track} = useAnalytics();

  useEffect(() => {
    (async () => {
      dispatch({type: 'SET_LOADING', loading: true});
      try {
        const me = await getMe();
        dispatch({type: 'RESTORE', user: me});
        track({
          group: event.group.Restore,
          action: event.action.Success,
        });
        identify({
          id: me.id,
          data: {
            email: me.email,
            knownAs: me.knownAs,
            createdAt: me.createdAt,
          },
        });
      } catch (err) {
        console.log('failed to get data for restore', err);
      }
      dispatch({type: 'SET_LOADING', loading: false});
    })();
  }, [dispatch, identify, track]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

// eslint-disable-next-line react/display-name
const withAuth =
  (component: React$ComponentType<any>): (any => React$Node) =>
  (props: any) =>
    <AuthProvider>{React.createElement(component, props)}</AuthProvider>;

export {AuthProvider, withAuth, useAuth, useAuthState};
