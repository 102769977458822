module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/locale","languages":["en","es","pt_BR"],"defaultLanguage":"en","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"pe0SYqbU5O0iEFB2ZlHxq75GSgXAFphW","devKey":"CNxuuWf7v16lTRBTtjbO7Mf2ODLZSPbp"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
