// @flow
import AsyncStorage from '@callstack/async-storage';

import React, {useCallback, useContext} from 'react';

import useAnalytics, {event} from '_hooks/useAnalytics';
import useFacebook from '_hooks/useFacebook';
import {getTokenBySignInWithEmail} from '_utils/auth/signIn';
import {getTokenBySignInWithFacebook} from '_utils/auth/signInWithFacebook';
import {getTokenBySignInWithMagicLink} from '_utils/auth/signInWithMagicLink';
import {getTokenBySignUpWithEmail} from '_utils/auth/signUp';
import {GqlError} from '_utils/errors';
import {getMe} from '_utils/profile';

import {useAuthState} from './state';
import type {Dispatch} from './types';

export const AuthDispatchContext: React$Context<?Dispatch> = React.createContext<?Dispatch>();

type AuthFunctions = {
  signInWithEmail: (email: string, password: string) => Promise<void>,
  signInWithMagicLink: (code: string) => Promise<void>,
  signInWithFacebook: () => Promise<void>,
  signUp: (email: string, password: string) => Promise<void>,
  signOut: () => Promise<void>,
  restore: () => Promise<void>,
  updateProfile: (profile: Object) => void,
  setMetadata: (metadata: {[key: string]: any}) => void,
};

export const useAuth = (): AuthFunctions => {
  const dispatch = useContext(AuthDispatchContext);
  if (!dispatch) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  const state = useAuthState();

  const {identify, track, reset: resetAnalytics} = useAnalytics();

  const {signIn: fbSignIn, signOut: fbSignOut} = useFacebook();

  const restore = useCallback(async () => {
    dispatch({type: 'SET_RESTORING', restoring: true});
    try {
      const me = await getMe();
      dispatch({type: 'RESTORE', user: me});
      track({
        group: event.group.Restore,
        action: event.action.Success,
      });
      identify({
        id: me.id,
        data: {
          email: me.email,
          knownAs: me.knownAs,
          createdAt: me.createdAt,
        },
      });
    } catch (err) {
      track({
        group: event.group.Login,
        action: event.action.Failed,
        data: {
          method: 'email',
        },
      });
      console.log('failed to get data for restore', err);
    } finally {
      dispatch({type: 'SET_RESTORING', restoring: false});
    }
  }, [dispatch, identify, track]);

  const signInWithEmail = useCallback(
    (email: string, password: string) =>
      (async () => {
        dispatch({type: 'SET_LOADING', loading: true});
        try {
          const token = await getTokenBySignInWithEmail(email, password);
          track({
            group: event.group.Login,
            action: event.action.Success,
            data: {
              method: 'email',
            },
          });
          track({
            name: event.af.login,
            data: {
              method: 'email',
            },
          });
          await AsyncStorage.setItem(process.env.GATSBY_AUTH_TOKEN_KEY, token);
          const me = await getMe();
          dispatch({type: 'SIGN_IN', signInType: 'RF', user: me});
          identify({
            id: me.id,
            data: {
              email: me.email,
              knownAs: me.knownAs,
              createdAt: me.createdAt,
            },
          });
        } catch (err) {
          track({
            group: event.group.Login,
            action: event.action.Failed,
            data: {
              method: 'email',
            },
          });
          throw new GqlError(err);
        } finally {
          dispatch({type: 'SET_LOADING', loading: false});
        }
      })(),
    [dispatch, identify, track],
  );

  const signInWithMagicLink = useCallback(
    async (code: string) => {
      dispatch({type: 'SET_LOADING', loading: true});
      try {
        const token = await getTokenBySignInWithMagicLink(code);
        track({
          group: event.group.Login,
          action: event.action.Success,
          data: {
            method: 'magic_link',
          },
        });
        track({
          name: event.af.login,
          data: {
            method: 'magic_link',
          },
        });
        await AsyncStorage.setItem(process.env.GATSBY_AUTH_TOKEN_KEY, token);
        const me = await getMe();
        dispatch({type: 'SIGN_IN', signInType: 'ML', user: me});
        identify({
          id: me.id,
          data: {
            email: me.email,
            knownAs: me.knownAs,
            createdAt: me.createdAt,
          },
        });
      } catch (err) {
        track({
          group: event.group.Login,
          action: event.action.Failed,
          data: {
            method: 'magic_link',
          },
        });
        throw new GqlError(err);
      } finally {
        dispatch({type: 'SET_LOADING', loading: false});
      }
    },
    [dispatch, identify, track],
  );

  const signInWithFacebook = useCallback(async () => {
    dispatch({type: 'SET_LOADING', loading: true});
    try {
      const fbAccesToken = await fbSignIn();
      if (!fbAccesToken) return;
      track({
        group: event.group.Login,
        action: event.action.Success,
        data: {
          method: 'facebook',
        },
      });
      track({
        name: event.af.login,
        data: {
          method: 'facebook',
        },
      });
      const token = await getTokenBySignInWithFacebook(fbAccesToken, state.metadata);
      await AsyncStorage.setItem(process.env.GATSBY_AUTH_TOKEN_KEY, token);
      const me = await getMe();
      dispatch({type: 'SIGN_IN', signInType: 'FB', user: me});
      identify({
        id: me.id,
        data: {
          email: me.email,
          knownAs: me.knownAs,
          createdAt: me.createdAt,
        },
      });
    } catch (err) {
      track({
        group: event.group.Login,
        action: event.action.Failed,
        data: {
          method: 'facebook',
        },
      });
    } finally {
      dispatch({type: 'SET_LOADING', loading: false});
    }
  }, [dispatch, fbSignIn, identify, track, state.metadata]);

  const signUp = useCallback(
    async (email: string, password: string) => {
      dispatch({type: 'SET_LOADING', loading: true});
      try {
        const token = await getTokenBySignUpWithEmail(email, password, state.metadata);
        await AsyncStorage.setItem(process.env.GATSBY_AUTH_TOKEN_KEY, token);
        track({
          group: event.group.Signup,
          action: event.action.Success,
          data: {
            method: 'email',
          },
        });
        track({
          name: event.af.complete,
          data: {
            af_registration_method: 'email',
          },
        });
        const me = await getMe();
        dispatch({type: 'SIGN_IN', signInType: 'RF', user: me});
        identify({
          id: me.id,
          data: {
            email: me.email,
            knownAs: me.knownAs,
            createdAt: me.createdAt,
          },
        });
      } catch (err) {
        track({
          group: event.group.Signup,
          action: event.action.Failed,
          data: {
            method: 'email',
          },
        });
      } finally {
        dispatch({type: 'SET_LOADING', loading: false});
      }
    },
    [dispatch, identify, track, state.metadata],
  );

  const signOut = useCallback(
    () =>
      (async () => {
        await fbSignOut();
        await AsyncStorage.removeItem(process.env.GATSBY_AUTH_TOKEN_KEY);
        dispatch({type: 'SIGN_OUT'});
        resetAnalytics();
      })(),
    [dispatch, fbSignOut, resetAnalytics],
  );

  const updateProfile = useCallback((profile: Object) => dispatch({type: 'UPDATE_PROFILE', profile}), [dispatch]);

  const setMetadata = useCallback(
    (metadata: {[key: string]: any}) => dispatch({type: 'SET_METADATA', metadata}),
    [dispatch],
  );

  return {
    restore,
    signInWithEmail,
    signInWithMagicLink,
    signInWithFacebook,
    signUp,
    signOut,
    updateProfile,
    setMetadata,
  };
};
