// @flow
import {gql} from 'graphql-request';

import {appClient} from '_client';
import {GqlError} from '_utils/errors';

const USER_FB_LOGIN = gql`
  mutation userFBLogin($accessToken: String!, $metadata: Map) {
    userFBLogin(accessToken: $accessToken, metadata: $metadata) {
      accessToken
      metadata
    }
  }
`;

export const getTokenBySignInWithFacebook = async (
  accessToken: string,
  metadata?: {[key: string]: any},
): Promise<string> => {
  try {
    const {userFBLogin} = await appClient.request(USER_FB_LOGIN, {accessToken, metadata});
    return userFBLogin.accessToken;
  } catch (err) {
    throw new GqlError(err);
  }
};
