// @flow
import { useCallback, useEffect, useState } from 'react';

import useScript from './useScript';

const config = {
  appId: process.env.GATSBY_FACEBOOK_APP_ID,
  autoLogAppEvents: true,
  xfbml: true,
  version: 'v9.0',
  onError: () => {},
};

type FacebookReturn = {
  accessToken: ?string,
  initing: boolean,
  signIn: () => Promise<?string>,
  signOut: () => Promise<void>,
};

const useFacebook = (): FacebookReturn => {
  const [scriptStatus] = useScript('https://connect.facebook.net/en_US/sdk.js');

  const [initing, setIniting] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (scriptStatus === 'l') {
      setIniting(true);
    }
  }, [scriptStatus]);

  useEffect(() => {
    if (scriptStatus !== 'r') return;

    setIniting(true);
    window.FB.init(config);

    window.FB.getLoginStatus((response) => {
      const { authResponse, status } = response;
      if (status === 'connected' && authResponse.accessToken) {
        setAccessToken(authResponse.accessToken);
      } else {
        setAccessToken(null);
      }
      setIniting(false);
    });
  }, [scriptStatus]);

  const signIn = useCallback(
    () =>
      new Promise((resolve) => {
        window.FB.login(
          (response) => {
            const { authResponse, status } = response;
            if (status === 'connected' && authResponse.accessToken) {
              setAccessToken(authResponse.accessToken);
              resolve(authResponse.accessToken);
            } else {
              setAccessToken(null);
              resolve(null);
            }
          },
          { scope: 'email' }
        );
      }),
    []
  );

  const signOut = useCallback(
    () =>
      new Promise((resolve) => {
        window.FB.getLoginStatus((response) => {
          const { authResponse, status } = response;
          if (status === 'connected' && authResponse.accessToken) {
            window.FB.logout(() => {
              setAccessToken(null);
              resolve();
            });
          } else {
            setAccessToken(null);
            resolve();
          }
        });
      }),
    []
  );

  return { accessToken, initing, signIn, signOut };
};

export default useFacebook;
