import AsyncStorage from '@callstack/async-storage';

import axios from 'axios';
import { GraphQLClient } from 'graphql-request';

const url = process.env.GATSBY_GRAPH_URL;
const appId = process.env.GATSBY_APP_ID;

const appClient = new GraphQLClient(url, {
  headers: {
    'X-Application-ID': appId,
    mode: 'cors',
  },
});

const runQuery = async (query, variables) => {
  const token = await AsyncStorage.getItem(process.env.GATSBY_AUTH_TOKEN_KEY);
  return appClient.request(query, variables, {
    authorization: token ? `Bearer ${token}` : '',
  });
};

const owClient = axios.create({
  baseURL: process.env.GATSBY_RITUAL_API_URL,
  headers: {
    'content-type': 'application/json',
    'access-control-allow-origin': '*',
    Authorization: `Token ${process.env.GATSBY_RITUAL_API_TOKEN}`,
  },
});

export { appClient, runQuery, owClient };
