// @flow
import {gql} from 'graphql-request';

import {runQuery} from '_client';
import {GqlError} from '_utils/errors';

const USER_SIGN_UP = gql`
  mutation userSignUpV2($user: UserSignUpArgs!) {
    userSignUpV2(user: $user) {
      accessToken
    }
  }
`;

export const getTokenBySignUpWithEmail = async (
  email: string,
  password: string,
  metadata?: {[key: string]: any},
): Promise<string> => {
  try {
    const {userSignUpV2} = await runQuery(USER_SIGN_UP, {
      user: {
        email,
        password,
        metadata,
      },
    });
    return userSignUpV2.accessToken;
  } catch (err) {
    throw new GqlError(err);
  }
};
